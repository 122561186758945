.formTitle[data-v-374f5c9a] {
  font-weight: bold;
  margin-bottom: 20px;
}
.w200[data-v-374f5c9a] {
  width: 200px;
}
.drawerHead[data-v-374f5c9a] {
  display: flex;
  justify-content: space-between;
}
